import Clappr, {} from 'clappr';
import LevelSelector from '@/utils/player/level-selector'
// import LevelSelector from 'clappr-level-selector-plugin'
import ClapprMarkersPlugin from 'clappr-markers-plugin'
import Vue from 'vue'
import a from '@/utils/axios/axios'

const player = {
  namespaced: true,
  state: () => ({
    player: null,
    currentTime: 0,
    currentTimeAndDuration: { current: 0, total: 1},
    accurateCurrentTime: 0,
    clip: null,
    time: null,
    playing: false,
    accurateInterval: null,
    starttime: null,
    endtime: null,
    playbackRate: 1,
    rates: [0.1, 0.2, 0.5, 1, 2, 4, 8],
    overlay: null, // { text: '3s', forward: true | false }
    looping: false,
    mediacontrol_visible: false,
    buffering: false,
    show_time_overlay: false,
    time_overlay_id: null,
    scroll_enabled: false,
    layers_paused_already: [] // an array of layer ids that have already been paused at :)
  }),
  mutations: {
    SET_MEDIACONTROL_VISIBLE(state, val) {
      state.mediacontrol_visible = val
    },
    ADD_LAYER_PAUSED(state, id) {
      state.layers_paused_already = [...state.layers_paused_already].concat(id)
    },
    CLEAR_LAYERS_PAUSED(state) {
      state.layers_paused_already = []
    },
    SET_BUFFERING(state, val) {
      state.buffering = val
    },
    setPlayer(state, player) {
      state.player = player
    },
    setTimeOnly(state, time) {
      state.currentTime = time
      state.currentTimeAndDuration = { ...state.currentTimeAndDuration, current: time }
    },
    setCurrentTime(state, time) {
      state.currentTime = time.current
      state.currentTimeAndDuration = time
    },
    setClip(state, clip) {
      state.clip = clip
    },
    setTime(state, time) {
      state.time = time
    },
    setLooping(state, looping) {
      state.looping = looping
    },
    SET_PLAYING(state, playing) {
      state.playing = playing
    },
    SET_ACCURATE_TIME(state, time) {
      state.accurateCurrentTime = time
    },
    SET_ACCURATE_INTERVAL(state, interval) {
      clearInterval(state.accurateInterval)
      state.accurateInterval = interval
    },
    SET_PLAYBACKRATE(state, rate) {
      state.playbackRate = rate
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload
    },
    CLEAR_INTERVAL(state) {
      clearInterval(state.accurateInterval)
      state.accurateInterval = null
    },
    SET_TIMEOVERLAY(state, val) {
      state.show_time_overlay = val
    },
    SET_TIME_OVERLAY_ID(state, val) {
      state.time_overlay_id = val
    },
    SET_SCROLL_ENABLED(state, val) {
      state.scroll_enabled = val
    }
  },
  actions: {
    destroy({ getters, commit, dispatch }) {
      dispatch('comment/resetComment', {}, {root: true})
      if(getters.player) getters.player.destroy()

      commit('setPlayer', null)
      commit('setClip', null)
      commit('setTime', null)
    },
    initPlayer({ commit, dispatch, getters, state }, options) {
      if(getters.player) getters.player.destroy()
      if(options.poster && !options.poster.includes('https')) {
        options.poster = options.poster.replace('http', 'https')
      }
      commit('setPlayer', null)
      commit('setClip', null)

      // Clappr.Log.setLevel(0)

      // var for scope inside the listeners
      var player = new Clappr.Player({
        ...options,
        autoPlay: true,
        disableKeyboardShortcuts: true,
        width: '100%',
        playInline: true,
        autoSeekFromUrl: true,
        useDvrControls: true,
        height: '100%',
        plugins: [LevelSelector],
        events: {
          onTimeUpdate: e => {
            commit('setCurrentTime', e) 
            commit('SET_ACCURATE_TIME', e?.current)
            dispatch('checkStartAndEndtime', {options, current: e.current})
          },
          onPlay: () => { 
            commit('SET_BUFFERING', false)
            commit('SET_PLAYING', true )
            getters.player.core.activePlayback.el.playbackRate = getters.playbackRate

            if(options.start_and_pause_at) {
              dispatch('seek', options.start_and_pause_at) 
              Vue.nextTick(() => {
                dispatch('pause')
                options.start_and_pause_at = null
              })
            }
          },
          onPause: () => { commit('SET_PLAYING', false )},
          onStop: () => { commit('SET_PLAYING', false )},
          onSeek: e => {
            commit('setTimeOnly', e)
            commit('SET_ACCURATE_TIME', e)
            dispatch('checkStartAndEndtime', {options, current: e})
          },
          onReady: () => {
            commit('setCurrentTime', { current: 0, total: player.getDuration() }) 

            if(options.start_and_pause_at) {
              Vue.nextTick(() => {
                dispatch('pause')
              })
              let time = options.start_and_pause_at;

              setTimeout(() => {
                dispatch('play')
                dispatch('pause')
                dispatch('seek', time)
              }, 1000)
            } else if(options.starttime) {
              Vue.nextTick(() => {
                dispatch('seek', options.starttime - (options.buffer || 0))
              })
            } else if (options.start_at) {
              Vue.nextTick(() => {
                dispatch('seek', options.start_at)
              })
            }
          }
        },
        markersPlugin: {
          markers: [
            // new ClapprMarkersPlugin.StandardMarker(0, "The beginning!"),
            // new ClapprMarkersPlugin.StandardMarker(90, "Something interesting."),
            // new ClapprMarkersPlugin.StandardMarker(450, "The conclusion.")
          ]
        },
        playback: {
          playInline: true,
        },
        attributes: {
          crossorigin: 'anonymous',
        },
      })

      // player.on(Clappr.Events.PLAYER_FULLSCREEN, (isFullscreen) => { 
      //   console.log('isFullscreen', isFullscreen);
      // });
      Vue.nextTick(() => {
        player.listenTo(player.core.getCurrentContainer(), 
        Clappr.Events.CONTAINER_STATE_BUFFERING,  
        function() { commit('SET_BUFFERING', true) })
      })
      //   player.core.mediaControl,
      //   Clappr.Events.MEDIACONTROL_HIDE,
      //   function(){console.log('abc')}
      // );


      // player.on(Clappr.Events.PLAYBACK_PLAY, () => { 
      //   console.log('jea')
      // });

      // player.listenTo(Clappr.Events.MEDIACONTROL_HIDE, function() { 
      //   console.log('jea')
      // });

      clearInterval(state.accurateInterval)
      commit('setPlayer', player)
      dispatch('startTimer')
    },
    handleScroll({ dispatch, getters }, e) {
      if(!getters.scroll_enabled) return
      dispatch('skip', e.deltaY / 30)
    },
    showCurrentTimeOnPlayerForTime({ commit, getters }, time) {
      const oldTimeOverlayTimeoutId = getters.time_overlay_id
      if(oldTimeOverlayTimeoutId) clearTimeout(oldTimeOverlayTimeoutId)

      commit('SET_TIMEOVERLAY', true)

      const timeOverlayTimeoutId = setTimeout(() => {
        commit('SET_TIMEOVERLAY', false)
      }, time)

      commit('SET_TIME_OVERLAY_ID', timeOverlayTimeoutId)
    },
    changePlaybackRate({ commit, getters }, rate) {
      if(!getters.player) return
      getters.player.core.activePlayback.el.playbackRate = rate
      commit('SET_PLAYBACKRATE', rate)
    },
    playbackRateUp({ dispatch, getters }) {
      let rates = [...getters.rates]
      let current = getters.playbackRate
      let old_index = rates.findIndex(r => r === current) 

      let newRate = rates[ Math.min(old_index + 1, rates.length - 1) ]

      dispatch('changePlaybackRate', newRate)
    },
    playbackRateDown({ dispatch, getters }) {
      let rates = [...getters.rates]
      let current = getters.playbackRate
      let old_index = rates.findIndex(r => r === current) 

      let newRate = rates[ Math.max(old_index - 1, 0 )]

      dispatch('changePlaybackRate', newRate)
    },
    checkStartAndEndtime({ dispatch }, {options, current}) {
      if(!options.starttime) options.starttime = 0;
      if (!options.endtime) options.endtime = options.starttime + 30;
      const buff = Math.abs(options.buffer || 0)
      if (
        current < options.starttime - buff - 0.5
        || current > options.endtime + buff
      ) {
        dispatch('seek', options.starttime - buff)
      }
    },
    startTimer({ getters, commit, dispatch }) {
      commit('CLEAR_INTERVAL')
      const accurateInterval = setInterval(async () => {
        let time;

        let previousFuzzyTime = getters.currentTime

        if(!getters.playing || getters.buffering) {
          time = previousFuzzyTime
          commit('SET_ACCURATE_TIME', time || 0)
          return
        }

        let previousTime = getters.accurateCurrentTime
        let newTime = await dispatch('playerCurrentTime')
        
        if(newTime - previousFuzzyTime < 0.25) {
          time = previousTime + 0.05 * getters.playbackRate
        } else {
          time = newTime
        }

        commit('SET_ACCURATE_TIME', time)

      }, 50)

      commit('SET_ACCURATE_INTERVAL', accurateInterval)
    },
    closeTimer({ commit }) {
      commit('CLEAR_INTERVAL')
      commit('SET_ACCURATE_INTERVAL', null)
    },
    seek({ getters }, time) {
      if(time == 0) return 
      if(!time) throw new Error('no time!')
      getters.player.seek(time)
    },
    play({ getters, dispatch }) {
      getters.player.play()

      // if the player is started again we want to remove the timeout that pauses for graphics
      dispatch('draw/removePauseTimeout', {}, { root: true })
    },
    pause({ getters }) {
      getters.player.pause()
    },
    toggle({ getters, dispatch }) {
      if(getters.playing) dispatch('pause')
      else dispatch('play')
    },
    playerCurrentTime({ getters }) {
      if(!getters.player) return 0
      return getters.player.getCurrentTime()
    },
    showOverlay({ commit }, value) {
      let text = value + 's'
      commit('SET_OVERLAY', { text, forward: value > 0 })

      setTimeout(() => {
        commit('SET_OVERLAY', null)
      }, 1000)
    },
    skip({ dispatch, getters }, value) {
      if(!value) return

      let current = getters.currentTime
      if(!current) return

      dispatch('seek', current + value)
      dispatch('showCurrentTimeOnPlayerForTime', 1000)
    },
    openClip({ commit, dispatch }, clip) {
      commit('setTime', null)
      commit('setClip', clip)
      commit('setLooping', clip.looping)
      dispatch('seek', clip.starttime)
      dispatch('getClipGraphics', clip.id)
      dispatch('play')
    },
    getClipGraphics({ commit }, id) {
      a.get(`/clip/${id}/graphics`)
        .then(e => {
          commit('draw/SET_LAYERS', e.data?.layers, { root: true })
          commit('draw/SET_OBJECTS', e.data?.objects, { root: true })
          commit('draw/SET_ORIGINAL_VIDEO_WIDTH', e.data?.original_video_width, { root: true })
        })
    },
    openTime({ commit, dispatch }, { time, starttime }) {
      commit('setClip', null)
      commit('setTime', time)
      dispatch('seek', starttime)
      dispatch('play')
    },
    closeClip({ commit }) {
      commit('draw/CLEAR', {}, { root: true })
      commit('setClip', null)
    },
    closeTime({ commit }) {
      commit('setTime', null)
    }
  },
  getters: {
    player: state => state.player,
    layers_paused_already: state => state.layers_paused_already,
    currentTime: state => state.currentTime,
    currentTimeAndDuration: state => state.currentTimeAndDuration,
    duration: state => state.currentTimeAndDuration?.total,
    playingClip: state => state.clip,
    clip: state => state.clip,
    playingTime: state => state.time,
    playing: state => state.playing,
    accurateCurrentTime: state => state.accurateCurrentTime,
    accurateInterval: state => state.accurateInterval,
    playbackRate: state => state.playbackRate,
    rates: state => state.rates,
    overlay: state => state.overlay,
    markerPlugin: () => ClapprMarkersPlugin,
    mediacontrol_visible: state => state.mediacontrol_visible,
    buffering: state => state.buffering,
    time_overlay_id: state => state.time_overlay_id,
    show_time_overlay: state => state.show_time_overlay,
    scroll_enabled: state => state.scroll_enabled
  }
}

export default player