
import a from '@/utils/axios/axios'

const auth = {
  namespaced: true,
  state: () => ({
    leagues: [],
    games: [],
    teams: [],
    sports: []
  }),
  mutations: {
    SET_LEAGUES(state, payload) {
      state.leagues = payload
    },
    SET_GAMES(state, payload) {
      state.games = payload
    },
    SET_SPORTS(state, payload) {
      state.sports = payload
    },
    ADD_GAME(state, game) {
      state.games = [game].concat([...state.games])
    },
    PUT_GAME(state, game) {
      let mod = [...state.games]

      let i = mod.findIndex(g => g.id == game.id)
      mod[i] = game

      state.games = mod
    },
    REMOVE_GAME_BY_ID(state, id) {
      state.games = [...state.games].filter(g => g.id != id)
    },
    SET_LEAGUE_TEAMS(state, teams) {
      state.teams = teams
    }
  },
  actions: {
    initLeagues({ commit }) {
      // let teamId = window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.get(`/league`)
          .then(res => {
            resolve(res)
            commit('SET_LEAGUES', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initTeams({ commit }, league_id) {
      // let teamId = window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.get(`/league/${league_id}/joinedteam`)
          .then(res => {
            resolve(res)
            commit('SET_LEAGUE_TEAMS', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initSports({ commit }) {
      return new Promise((resolve, reject) => {
        a.get(`/admin/sports`)
          .then(res => {
            resolve(res)
            commit('SET_SPORTS', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initGames({ commit, rootGetters }, { league_id, season }) {
      season = season || new Date().getFullYear()

      league_id = league_id || rootGetters['user/leagueId']
      return new Promise((resolve, reject) => {
        a.get(`/league/${league_id}/game?season=${season}`)
          .then(res => {
            resolve(res)
            commit('SET_GAMES', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    saveGame({ commit }, game) {
      console.log('save game')
      return new Promise((resolve, reject) => {
        a.post(`/league/game`, game)
          .then(res => {
            resolve(res)
            commit('ADD_GAME', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editGame({ commit }, game) {
      return new Promise((resolve, reject) => {
        a.put(`/league/game/${game.id}`, game)
          .then(res => {
            resolve(res)
            commit('PUT_GAME', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteGame({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/league/game/${id}`)
          .then(() => {
            commit('REMOVE_GAME_BY_ID', id)
            resolve('ok')
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  },
  getters: {
    leagues: state => state.leagues,
    games: state => state.games,
    teams: state => state.teams,
    sports: state => state.sports,
    leagueById: (state) => (id) => { return state.leagues.find(l => l.id == id)},
    sportById: (state) => (id) => { return state.sports.find(l => l.id == id)}
  }
}

export default auth